import "../content/_loader.less";

import { BffClient } from "./bootstrap/bff";

void (async () => {
  const user = await ensureUser();
  const { main } = await import("./main");
  await main({ user });
})();

async function ensureUser() {
  const bffClient = new BffClient();
  const user = await bffClient.getUser();
  if (user) {
    return user;
  }

  window.location.href = bffClient.getLoginUrl();
  await new Promise(() => {
    /* never resolve */
  });
  throw new Error("Should never get here");
}
