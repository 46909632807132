export class BffClient {
  private readonly baseUrl: string;
  private readonly document: Document;

  constructor(options?: { baseUrl?: string; document?: Document }) {
    this.baseUrl = options?.baseUrl;
    this.document = options?.document ?? document;
  }

  public async getUser(options?: { slide?: boolean; baseUrl?: string }): Promise<Record<string, unknown>> {
    const url = this.getUrl("bff/user", options?.baseUrl);
    if (!options?.slide) {
      url.searchParams.set("slide", "false");
    } // else do nothing, since slide=true is the default on the server side

    const request = new Request(url, { headers: { "X-CSRF": "1" } });
    const response = await fetch(request);
    if (response.ok) {
      const json = await response.json();
      return json == null ? null : this.toProfile(json as { type: string; value: unknown }[]);
    }

    if (response.status === 401) {
      return null;
    }

    throw new Error("Failed to retrieve the user.");
  }

  public getLoginUrl(options?: { returnUrl?: string | false; baseUrl?: string }): string {
    return this.getLocalUrl("bff/login", options?.baseUrl, this.getReturnUrl(options?.returnUrl));
  }

  public getLogoutUrl(options?: { logoutUrl?: string; returnUrl?: string | false; baseUrl?: string }): string {
    return this.getLocalUrl(
      options?.logoutUrl ?? "bff/logout",
      options?.baseUrl,
      this.getReturnUrl(options?.returnUrl),
    );
  }

  private getUrl(url: string, baseUrl: string): URL {
    return new URL(url, baseUrl ?? this.baseUrl ?? this.document.baseURI);
  }

  private getReturnUrl(returnUrl: string | false): string {
    if (returnUrl === false) {
      return null;
    }

    if (typeof returnUrl === "string") {
      return returnUrl;
    }

    const location = this.document.location;
    return location.pathname + location.search + location.hash;
  }

  private getLocalUrl(url: string, baseUrl: string, returnUrl?: string): string {
    const value = this.getUrl(url, baseUrl);
    if (returnUrl) {
      value.searchParams.set("returnUrl", returnUrl);
    }

    return value.pathname + value.search;
  }

  private toProfile(claims: { type: string; value: unknown }[]): Record<string, unknown> {
    // https://docs.duendesoftware.com/identityserver/v6/bff/session/management/#user
    // Example claims:
    // {"type": "sid", "value": "173E788068FFB728806501F4F46C52D6"},
    // {"type": "sub", "value": "88421113"},
    // {"type": "idp", "value": "local"},
    // {"type": "name", "value": "Bob Smith"},
    // {"type": "bff:logout_url", "value": "/bff/logout?sid=173E788068FFB728806501F4F46C52D6"},
    // {"type": "bff:session_expires_in", "value": 28799},
    // {"type": "bff:session_state", "value": "q-Hl1V9a7FCZE5o-vH9qpmyVKOaeVfMQBUJLrq-lDJU.013E58C33C7409C6011011B8291EF78A"}

    return claims.reduce(
      (acc, it) => {
        const existing = acc[it.type];
        if (Array.isArray(existing)) {
          // if array, append
          existing.push(it.value);
        } else if (existing !== undefined) {
          // if value, to array
          acc[it.type] = [existing, it.value];
        } else {
          acc[it.type] = it.value;
        }

        return acc;
      },
      {} as Record<string, unknown>,
    );
  }
}
